import {
  observable,
  action,
  makeObservable,
  computed,
  reaction,
  IObservableArray,
} from 'mobx'

import Discussion from '../Model/Discussion'
import Event from '../Model/Event'
import ModelBase from '../Model/ModelBase'
import Organization from '../Model/Organization'
import VisitTimer from '../Model/VisitTimer'

import RootStore from './RootStore'
import UserStore from './UserStore'
import StoreResourceBase from './StoreResourceBase'

import { getSlugAndPath, slugMatchesOrgId } from '../lib/slug'
import { handleSocketListener, getSocket } from '../lib/socket'
import { useLog } from '../lib/log'
import { isBase64String } from '../lib/utils'

import { AvailableOrg, InvestmentReportsMetaData, OrgProps } from '../types'
import APIResponseType from '../lib/client/apis/APIResponseType'

const { API_HOST } = _global

const log = useLog()

export default class OrgStore extends StoreResourceBase {
  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      visitTimer: observable,
      appUpdated: observable,
      navigationExpanded: observable,
      checkItemExistsInOrg: action,
      init: action,
      getAvailableOrgs: action,
      changeOrg: action,
      currentOrg: observable,
      setCurrentOrg: action,
      orgs: observable,
      availableOrgs: observable,
      getLogoUrl: action,
      unreadCounts: computed,
      metaData: observable,
      getMetaData: action,
      saveMetaData: action,
      fetchAllItems: action,
      allItemsLoaded: observable,
      impersonate: action,
      syncQuarterlyInvestmentReportsMetaData: action,
      syncInvestmentAccounts: action,
      syncCRMAccounts: action,
      flushInvestmentCache: action,
      iCalLink: computed,
      orgsMembersFetched: observable,
      resetAppStore: action,
      globalsSet: observable,
      handleInitTasks: action,
      logoUrl: observable,
      joinOrganization: action,
      getModel: action,
    })
    this.currentOrg = new Organization(this.rootStore)

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.orgs, this.handleSocket)
  }

  metaData: unknown[] = []

  availableOrgs: IObservableArray<AvailableOrg> = observable.array([])

  orgs: IObservableArray<Organization> = observable.array([])

  currentOrg: Organization = undefined

  globalsSet = false

  appUpdated = false

  allItemsLoaded = false

  visitTimer: VisitTimer | undefined = undefined

  orgsMembersFetched = false

  navigationExpanded = false

  logoUrl = ''

  joinOrgError = false

  getModel = (data?: OrgProps) => {
    if (data?._id) {
      const org = this.orgs.find(orgToFind => orgToFind._id === data._id)

      if (org) {
        return org
      }
    }
    return new Organization(this.rootStore, data)
  }

  init = async () => {
    this.logoUrl = await this.getLogoUrl()
    this.rootStore.appLoaded = true
  }

  joinOrganization = async (token: string) => {
    try {
      const res = await this.rootStore.client.org.joinWithInvite({
        token,
        deviceHash: this.rootStore.commonStore.deviceHash,
        deviceInfo: this.rootStore.commonStore.deviceInfo,
      })
      const { jwt, org_id, newUserSignup } = res.data
      localStorage.setItem('jwt', jwt)
      localStorage.setItem('currentOrgId', org_id)
      sessionStorage.setItem('currentOrgId', org_id)

      if (newUserSignup) {
        localStorage.setItem('newSignup', '1')
        this.rootStore.userStore.isNewUserSignup = true
      }

      return Promise.resolve(res.data)
    } catch (e) {
      this.joinOrgError = true
      log.code('org306', { error: e })
      return Promise.reject()
    }
  }

  handleInitTasks = async () => {
    await this.getAvailableOrgs()

    const { slug } = getSlugAndPath()

    if (slug) {
      await this.checkItemExistsInOrg()
    }

    await this.setCurrentOrg()

    if (this.currentOrg.isImpersonating) {
      this.getAvailableOrgs()
    }

    this.rootStore.handleAppConnectSocket()
    this.rootStore.alertStore.getAlerts()
    this.rootStore.investmentBillingStore.getData()

    return true
  }

  get isHostUser() {
    return (
      !this.currentOrg.isImpersonating &&
      this.currentOrg.members.some(
        user => user._id === this.rootStore.userStore._id && user.isHostUser
      )
    )
  }

  getOrgNameFromList = (org_id: string) => {
    return this.availableOrgs.some(o => o.value === org_id)
      ? this.availableOrgs.find(o => o.value === org_id).label
      : ''
  }

  getMetaData = async () => {
    if (
      !this.rootStore.commonStore.jwt ||
      !this.currentOrg ||
      !this.currentOrg._id
    )
      return

    try {
      const res = await this.rootStore.client.org.getAllMetaData({
        org_id: this.currentOrg ? this.currentOrg._id : '',
      })
      this.metaData = res.data
    } catch (e) {
      // no-op
    }
  }

  saveMetaData = async (data: { key: string; org_id: string }) => {
    data.org_id = this.currentOrg ? this.currentOrg._id : ''
    const res = this.rootStore.client.org.saveMetaData(data)
    const existing = this.metaData.filter(
      (piece: { key: string }) => piece.key === data.key
    )

    if (!existing || existing.length === 0) {
      this.metaData.push(res)

      return
    }

    this.metaData = this.metaData.map((current: { key: string }) => {
      if (current.key === data.key) {
        return data
      }

      return current
    })
  }

  getAvailableOrgs = async () => {
    if (!this.rootStore.commonStore.jwt) {
      return false
    }

    const { data: options } = await this.rootStore.client.org.getAvailableOrgs(
      this.currentOrg.isHost ? this.currentOrg.impersonatingId : ''
    )

    this.availableOrgs.replace(options || [])

    return this.availableOrgs
  }

  changeOrg = async (org: string) => {
    if (this.currentOrg.isImpersonating) {
      this.impersonate('', false)
    }

    // Make sure user can switch to this org
    if (!this.availableOrgs.find(o => o.value === org)) {
      location.href = '#/'
      return undefined
    }

    if (this.currentOrg._id === org) {
      return undefined
    }

    this.rootStore.appLoaded = false
    const currentOrg = await this.getOrgById(org)
    const currentOrgId = currentOrg ? currentOrg._id : undefined
    sessionStorage.setItem('currentOrgId', currentOrgId)

    await this.setCurrentOrg()
    this.rootStore.commonStore.callSegment()
    this.rootStore.investmentBillingStore.getData()

    return true
  }

  setCurrentOrg = async () => {
    const params = new URLSearchParams(`?${location.href.split('?')[1] || ''}`)
    const currentOrgLS =
      params.get('changeId') ||
      sessionStorage.getItem('currentOrgId') ||
      localStorage.getItem('currentOrgId')

    this.currentOrg = currentOrgLS
      ? await this.getOrgById(currentOrgLS)
      : this.availableOrgs && this.availableOrgs.length > 0
        ? await this.getOrgById(this.availableOrgs[0].value)
        : new Organization(this.rootStore)

    if (!this.currentOrg?._id) {
      throw new Error('Your user is no longer active')
    }

    sessionStorage.setItem('currentOrgId', this.currentOrg._id)
    localStorage.setItem('currentOrgId', this.currentOrg._id)

    if (this.rootStore.commonStore.visitTimer) {
      this.rootStore.commonStore.visitTimer.recordVisit()
      this.rootStore.commonStore.visitTimer.reset(
        this.currentOrg._id,
        this.rootStore.userStore._id
      )
    } else {
      this.rootStore.commonStore.setVisitTimer(
        this.currentOrg._id,
        this.rootStore.userStore._id
      )
    }

    this.currentOrg.getMemberRecords(false).catch(() => true)
    this.currentOrg.getMembers(true).catch(() => true)
    this.currentOrg.fetchSmartTagData().catch(() => true)
    this.currentOrg.getContactManagement().catch(() => true)
    this.currentOrg.getRelationshipManagement().catch(() => true)
    await this.fetchAllItems()
    return true
  }

  // checks to see if this item in the url belongs to the org
  // if it doesn't below then it will switch you to the correct org
  checkItemExistsInOrg = async () => {
    if (!this.rootStore.userStore._id) {
      return false
    }

    const { slug, path } = getSlugAndPath()

    if (slug) {
      let changedOrg = false
      const inThisOrgOrHost =
        slugMatchesOrgId(slug, this.currentOrg._id) ||
        (['news-updates', 'posts'].includes(path) &&
          slugMatchesOrgId(slug, this.currentOrg.host))

      if (!inThisOrgOrHost && this.availableOrgs.length > 0) {
        await Promise.all(
          this.availableOrgs.map(org => {
            if (!changedOrg && slugMatchesOrgId(slug, org.value)) {
              changedOrg = true
              return this.changeOrg(org.value)
            }
            return Promise.resolve()
          })
        )
        // This is for users that are members under different hosts
        // and a global post link is followed
        //
        if (!changedOrg) {
          await Promise.all(
            this.availableOrgs.map(org => {
              if (!changedOrg && org.host && slugMatchesOrgId(slug, org.host)) {
                changedOrg = true
                return this.changeOrg(org.value)
              }
              return Promise.resolve()
            })
          )
        }
      }
      return !changedOrg
    }
    return true
  }

  fetchingOrgPromises: { [key: string]: APIResponseType } = {}

  getOrgById = async (orgId: string): Promise<Organization | undefined> => {
    if (!this.rootStore.commonStore.jwt) {
      return undefined
    }

    if (!this.orgs.some(org => org._id === orgId)) {
      if (!this.fetchingOrgPromises[orgId]) {
        this.fetchingOrgPromises[orgId] =
          this.rootStore.client.org.getOrgById(orgId)
      }

      const { data } = await this.fetchingOrgPromises[orgId]
      delete this.fetchingOrgPromises[orgId]

      if (data) {
        if (!this.orgs.some(o => o._id === data._id)) {
          const org = await new Organization(this.rootStore, data)
          org.getMembers().catch(() => true)
          this.orgs.push(org)
        }
      }
    }

    return this.orgs.find(org => org._id === orgId)
  }

  fetchAllItems = async (org = this.currentOrg) => {
    this.allItemsLoaded = false

    try {
      if (!org || !org._id) {
        return
      }

      if (!this.rootStore.commonStore.jwt) {
        return
      }

      const currentOrgId = org._id.toString()

      const { data } = await this.rootStore.client.org.fetchAllItems(
        currentOrgId,
        org.impersonatingId
      )

      this.rootStore.campaignStore.loadData(data.campaigns)
      this.rootStore.contactStore.loadData(
        data.contacts ? data.contacts : { org_id: currentOrgId }
      )
      this.rootStore.contentStore.loadData(data.content)
      this.rootStore.crmStore.getData()
      this.rootStore.discussionStore.loadData(data.discussions)
      this.rootStore.documentStore.loadDocuments(data.documents)
      this.rootStore.eventStore.loadData(data.events)
      this.rootStore.orgFormStore.getOrgForms()
      this.rootStore.pollStore.loadData(data.polls)
      this.rootStore.templateStore.getTemplates()
      this.rootStore.starStore.getStars()
      this.rootStore.todoStore.getTodos()
      this.rootStore.tagStore.getTags()

      if (!org.impersonatingId) {
        this.rootStore.starStore.loadData(data.stars)

        if (this.currentOrg.host) {
          this.rootStore.documentStore.getHostOrgDocuments()
        }

        if (this.currentOrg.isHost) {
          this.rootStore.client.org
            .getMemberOrgPermissionedResources(currentOrgId)
            .then(
              (memberDataRes: {
                data: { discussions: Discussion[]; events: Event[] }
              }) => {
                const { discussions, events } = memberDataRes.data
                if (discussions.length) {
                  this.rootStore.discussionStore.addDiscussions(discussions)
                }
                if (events.length) {
                  this.rootStore.eventStore.addEvents(events)
                }
              }
            )
            .catch((e: Error) => {
              log.warn({
                category: 'resources',
                message: e.message,
              })
            })
        }
      }

      this.allItemsLoaded = true
      this.rootStore.appLoaded = true
    } catch (e) {
      this.rootStore.campaignStore.campaigns = observable.array([])
      this.rootStore.contactStore.resetStore()
      this.rootStore.contentStore.content = observable.array([])
      this.rootStore.discussionStore.discussions = observable.array([])
      this.rootStore.documentStore.documents = undefined
      this.rootStore.eventStore.events = observable.array([])
      this.rootStore.documentStore.hostOrgDocuments = undefined
      this.rootStore.pollStore.polls = observable.array([])
      this.rootStore.starStore.resetStore()
      this.rootStore.tagStore.tags = observable.array([])
      this.rootStore.templateStore.templates = observable.array([])
      this.allItemsLoaded = true
      this.rootStore.appLoaded = true

      log.error({
        error: e,
        message: e.message,
      })

      throw e
    }
  }

  get unreadCounts() {
    const { allItems } = this.rootStore

    if (!allItems || allItems.length === 0) {
      return {
        content: false,
        events: false,
        polls: false,
        discussions: false,
        memberOrgMessages: false,
      }
    }

    const now = Date.now()
    const idToCheck =
      this.currentOrg.impersonatingId || this.rootStore.userStore._id

    const getForCategory = (category: string) => {
      const hasNotBeenRead = (item: ModelBase) => {
        if (item) {
          if ([item.author, item.creator].includes(idToCheck)) {
            return false
          }
          if (item.readBy && Array.isArray(item.readBy)) {
            return !item.readBy.includes(idToCheck)
          }

          return true
        }

        return false
      }

      return (
        allItems.filter(
          i =>
            i.modelCollection === category &&
            (category === 'discussions'
              ? !i.parent &&
                i.org_id === this.currentOrg._id &&
                Array.isArray(i.messages) &&
                i.messages[i.messages.length - 1] &&
                Array.isArray(i.messages[i.messages.length - 1].readBy) &&
                !i.messages[i.messages.length - 1].readBy.includes(idToCheck)
              : category === 'events'
                ? i.end > now && hasNotBeenRead(i)
                : hasNotBeenRead(i))
        ).length || false
      )
    }

    return {
      content: getForCategory('content'),
      events: getForCategory('events'),
      polls: getForCategory('polls'),
      discussions: getForCategory('discussions'),
      memberOrgMessages: this.currentOrg.isHost
        ? allItems.filter(
            i =>
              i.modelCollection === 'discussions' &&
              !i.parent &&
              i.org_id !== this.currentOrg._id &&
              Array.isArray(i.messages) &&
              i.messages[i.messages.length - 1] &&
              Array.isArray(i.messages[i.messages.length - 1].readBy) &&
              !i.messages[i.messages.length - 1].readBy.includes(idToCheck)
          ).length
        : false,
    }
  }

  get iCalLink() {
    return `${API_HOST}/orgs/v1/${this.currentOrg._id}/events/${this.rootStore.userStore._id}/OrganizationEvents.ics`
  }

  getLogoUrl = async (): Promise<string> => {
    try {
      const { data } = await this.rootStore.client.org.getLogoUrl()

      if (data && data.url) {
        if (isBase64String(data.url)) {
          return `data:image/png;base64,${data.url}`
        }

        return data.url
      }
    } catch (e) {
      //
    }

    return `${window.location.origin}/assets/logo.png`
  }

  impersonate = async (id: string, update = true) => {
    localStorage.removeItem('impersonatingId')
    this.currentOrg.impersonatingId = undefined

    if (this.currentOrg?.currentOrgRole === 'admin') {
      this.rootStore.commonStore.toggleManagingDirectory()
      this.currentOrg.impersonatingId = id

      if (id) {
        localStorage.setItem('impersonatingId', id)
      }

      if (update) {
        await this.fetchAllItems()
        await this.rootStore.alertStore.getAlerts()
      }
    }

    await this.getAvailableOrgs()
  }

  syncQuarterlyInvestmentReportsMetaData = (
    orgId: string,
    data: InvestmentReportsMetaData[]
  ) => {
    this.orgs.replace(
      this.orgs.map(org => {
        if (org.host === orgId) {
          org.hostQuarterlyInvestmentReportsMetaData = data || []
        }
        return org
      })
    )
  }

  syncCRMAccounts = async (showNotification = true) => {
    if (
      this.currentOrg &&
      this.currentOrg.isHost &&
      this.currentOrg.externalCRMProvider
    ) {
      try {
        const { data } = await this.rootStore.client.crm.syncAccounts(
          this.currentOrg._id
        )
        if (data && showNotification) {
          this.rootStore.addNotificationItem({
            success: true,
            message: `Successfully sync'd ${
              this.currentOrg.externalCRMProviderDisplayName
            }. ${data.count} account${
              +data.count === 1 ? '' : 's'
            } have been sync'd`,
          })
        }
      } catch (e) {
        if (showNotification) {
          this.rootStore.addNotificationItem({
            message: `Sync with ${this.currentOrg.externalCRMProviderDisplayName} failed`,
            error: true,
          })
        }
      }

      return true
    }

    return false
  }

  syncInvestmentAccounts = async (showNotification = true) => {
    if (
      this.currentOrg &&
      this.currentOrg.isHost &&
      this.currentOrg.performanceReportingProvider
    ) {
      try {
        const { data } = await this.rootStore.client.org.syncInvestmentAccounts(
          this.currentOrg._id,
          undefined
        )
        if (data && showNotification) {
          this.rootStore.addNotificationItem({
            success: true,
            message: `Successfully sync'd ${
              this.currentOrg.performanceReportingProviderDisplayName
            }. ${data.updated} account${
              +data.updated === 1 ? '' : 's'
            } have been sync'd`,
          })
        }
      } catch (e) {
        if (showNotification) {
          this.rootStore.addNotificationItem({
            message: `Sync with ${this.currentOrg.performanceReportingProviderDisplayName} failed`,
            error: true,
          })
        }
      }

      return true
    }

    return false
  }

  flushInvestmentCache = async () => {
    if (this.currentOrg && this.currentOrg.isHost) {
      try {
        const { data } = await this.rootStore.client.org.flushInvestmentCache(
          this.currentOrg._id,
          undefined
        )
        if (data) {
          this.orgs.forEach(o => o.resetInvestments())
          return this.rootStore.addNotificationItem({
            message: `Successfully cleared ${this.currentOrg.performanceReportingProviderDisplayName} cached data`,
            success: true,
          })
        }
      } catch (e) {
        //
      }

      return this.rootStore.addNotificationItem({
        message: `Failed to clear ${this.currentOrg.performanceReportingProviderDisplayName} cached data`,
        error: true,
      })
    }
    return false
  }

  resetAppStore = () => {
    localStorage.removeItem('impersonatingId')
    this.currentOrg = new Organization(this.rootStore)
    this.orgs.clear()
    this.availableOrgs.clear()
    this.rootStore.alertStore.alerts = []
    this.rootStore.alertStore.alertsLastLoaded = false
    this.allItemsLoaded = false
    this.rootStore.commonStore.token = undefined
    this.rootStore.userStore = new UserStore(this.rootStore)
    this.rootStore.commonStore.visitTimer = undefined
    this.rootStore.crmStore.reset()
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('orgs', Organization, this.orgs, this.rootStore)
      const socket = getSocket()

      socket.on('orgs', this.getAvailableOrgs)
    }
  }
}
