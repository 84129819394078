import axios, { AxiosRequestConfig } from 'axios'

// Import API's
import * as alerts from './apis/alerts'
import * as analytics from './apis/analytics'
import * as auditLogs from './apis/audit-logs'
import * as campaigns from './apis/campaigns'
import * as contactManagement from './apis/contactManagement'
import * as contacts from './apis/contacts'
import * as content from './apis/content'
import * as crm from './apis/crm'
import * as discussions from './apis/discussions'
import * as documents from './apis/documents'
import * as events from './apis/events'
import * as guests from './apis/guests'
import * as investmentBilling from './apis/investmentBilling'
import * as meetings from './apis/meetings'
import * as notifications from './apis/notifications'
import * as notify from './apis/notify'
import * as org from './apis/org'
import * as organizationForms from './apis/organizationForms'
import * as polls from './apis/polls'
import * as user from './apis/user'
import * as readby from './apis/readby'
import * as relationshipManagement from './apis/relationshipManagement'
import * as shorties from './apis/shorties'
import * as stars from './apis/stars'
import * as tags from './apis/tags'
import * as templates from './apis/templates'
import * as todos from './apis/todos'

const { API_HOST } = _global

const apiOptions: string[] = [
  'alerts',
  'analytics',
  'auditLogs',
  'campaigns',
  'contacts',
  'contactManagement',
  'content',
  'crm',
  'discussions',
  'documents',
  'events',
  'guests',
  'investmentBilling',
  'meetings',
  'notifications',
  'notify',
  'org',
  'organizationForms',
  'polls',
  'user',
  'readby',
  'shorties',
  'stars',
  'tags',
  'templates',
  'todos',
  'relationshipManagement',
]

// Set API's
const apis = {
  alerts,
  analytics,
  auditLogs,
  campaigns,
  contacts,
  contactManagement,
  content,
  crm,
  discussions,
  documents,
  events,
  guests,
  investmentBilling,
  meetings,
  notifications,
  notify,
  org,
  organizationForms,
  polls,
  user,
  readby,
  shorties,
  stars,
  tags,
  templates,
  todos,
  relationshipManagement,
}

// Request
export default class Client {
  instance = axios.create({})

  alerts: typeof alerts

  analytics: typeof analytics

  auditLogs: typeof auditLogs

  campaigns: typeof campaigns

  contacts: typeof contacts

  contactManagement: typeof contactManagement

  content: typeof content

  crm: typeof crm

  discussions: typeof discussions

  documents: typeof documents

  events: typeof events

  guests: typeof guests

  investmentBilling: typeof investmentBilling

  meetings: typeof meetings

  notifications: typeof notifications

  notify: typeof notify

  org: typeof org

  organizationForms: typeof organizationForms

  polls: typeof polls

  user: typeof user

  readby: typeof readby

  relationshipManagement: typeof relationshipManagement

  shorties: typeof shorties

  stars: typeof stars

  tags: typeof tags

  templates: typeof templates

  todos: typeof todos

  constructor() {
    this._attachAPIs()
  }

  // @todo need to change this to got and http/2
  // eslint-disable-next-line class-methods-use-this
  get tokenHeader() {
    const jwt = localStorage.getItem('jwt')
    return jwt !== null ? { Authorization: `Bearer ${jwt}` } : {}
  }

  _attachAPIFunction(api: object, apiName: string) {
    Object.keys(api).forEach((fnName: string) => {
      if (typeof api[fnName] === 'function') {
        this[apiName][fnName] = (...args) =>
          this._makeRequest(api[fnName], ...args)
      }
    })
  }

  _attachAPIs() {
    apiOptions.forEach(apiName => {
      this[apiName] = {}
      const api = apis[apiName]

      if (api && typeof api === 'object') {
        const keys = Object.keys(api)
        const type = typeof api[keys[0]]
        if (type === 'function') {
          this._attachAPIFunction(api, apiName)
        }
      }
    })
  }

  async _makeRequest(
    reqDefFunc: (args: object) => AxiosRequestConfig,
    ...args
  ): Promise<{ data: string | object }> {
    const props = reqDefFunc(...args)
    const { headers } = props

    props.headers = Object.assign(this.tokenHeader, props.headers || {})
    props.headers = {
      'Content-Type': 'application/json',
      ...this.tokenHeader,
      ...headers,
    }

    if (props.method !== 'GET') {
      props.body = JSON.stringify(props.data)
    }

    if (props.params) {
      props.url += `?${new URLSearchParams(props.params)}`
    }

    try {
      const res = await fetch(`${API_HOST}${props.url}`, props)
      const text = await res.text()
      let data: string | object

      try {
        data = JSON.parse(text)
      } catch (e) {
        data = text
      }

      return new Promise((resolve, reject) => {
        if (res.status > 400) {
          reject(text)
        } else {
          resolve({ data })
        }
      })
    } catch (e) {
      if (e && e.response && e.response.statusCode === 401) {
        localStorage.removeItem('jwt')
        return undefined
      }
      throw e
    }
  }
}
