import React, {
  useContext,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useRootStore } from './RootStoreContext'

interface AuthContextType {
  login: (loginRedirect?: string) => void
  logout: (redirect?: string) => void
  tokenId: string
  authenticate: () => void
  processAutologin: (endLocation: string) => void
  autoLogInToken: string
}

const AuthContext = createContext<AuthContextType>({
  login: () => null,
  logout: () => null,
  tokenId: '',
  authenticate: () => null,
  processAutologin: () => null,
  autoLogInToken: '',
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const rootStore = useRootStore()
  const { commonStore, authStore, client } = rootStore
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const autoLogInToken = searchParams.get('autologintoken')
  const tokenId = searchParams.get('token')

  const login = (loginRedirect?: string) => {
    const redirect = loginRedirect || '/dashboard'
    navigate(redirect, { state: location.state })
  }

  const logout = async (redirect = '/login') => {
    await authStore.logout()
    navigate(redirect)
  }

  const processAutologin = async (endLocation: string) => {
    rootStore.appLoaded = false

    try {
      await authStore.logout()

      const {
        data: { inviteHash, token, destination },
      } = await client.user.getUserFromAutoLoginToken({
        autologintoken: autoLogInToken,
        deviceHash: commonStore.deviceHash,
      })

      if (inviteHash) {
        const newState = { state: { from: endLocation } }
        const matches = inviteHash.match(/#(.*)/)

        if (matches && matches.length === 2) {
          return navigate(matches[1], newState)
        }

        return navigate(inviteHash.slice(1), newState)
      }

      await authStore.handleResponseTokenData(token)

      if (destination) {
        const matches = destination.match(/#(.*)/)

        if (matches && matches.length === 2) {
          return login(matches[1])
        }

        return login(destination)
      }

      return login(location?.state?.from || '/dashboard')
    } catch (e) {
      const redirectTo = endLocation || location?.state?.from || '/dashboard'
      rootStore.appLoaded = true
      authStore.isHandlingAuthentication = false
      authStore.loginError =
        e?.response?.data ||
        e?.message ||
        'Something went wrong. Please contact support'
      navigate('/login', {
        state: {
          from: redirectTo,
        },
      })
    }

    return false
  }

  const authenticate = (pathname?: string) => {
    if (autoLogInToken) {
      navigate(`/login${location.search}`, {
        state: {
          from: location.pathname,
          purpose: 'autologin',
          autoLogInToken,
          search: location.search,
        },
      })
    } else if (commonStore.jwt) {
      authStore
        .handleResponseTokenData(commonStore.jwt)
        .then(() => {
          if (pathname !== location.pathname) {
            const extra = searchParams.size ? `?${searchParams.toString()}` : ''

            login(`${location.pathname || '/dashboard'}${extra}`)
          }
        })
        .catch(e => {
          authStore.loginError = e?.response?.data || e?.message
          navigate('/login', { state: { from: location.pathname, error: e } })
        })
    }
  }

  useEffect(() => {
    authenticate()
  }, [])

  const value = useMemo(
    () => ({
      logout,
      login,
      processAutologin,
      tokenId,
      autoLogInToken,
      authenticate,
    }),
    [location.pathname, autoLogInToken]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
